import React, {useState,useEffect } from "react"
import {Link, StaticQuery, graphql } from "gatsby"

import MenuItem from "./MenuItem"

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const FOOTER_MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETFOOTERMENU {
        wpgraphql {
            menuItems(where: {location: FOOTER_MENU}, first: 30) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(process.env.WORDPRESS_URL, ``)
}

const RenderMenuItem = menuItem => {

  const extension=menuItem.url.split('.').pop();

  if(extension!="pdf"){
    const link = createLocalLink(menuItem.url)
    return (
      <li
        key={menuItem.id}
        className="not pdf"
      >
        <Link to={link}><span>{menuItem.label}</span></Link>
      </li>
    )
  }
  else{
    const link = createLocalLink(menuItem.url)
    return (
      <li
        key={menuItem.id}
        className="pdf"
      >
        <a href={link}><span>{menuItem.label}</span></a>
      </li>
    )
  }



  }


const FooterMenu = () => {

    const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
    const [url, setUrl] = useState(pageUrl)
    const [showFilter,setShowFilter] = useState(false)
    const [showFeefoBadge,setFeefoBadge] = useState(false)

    const updatePosts=(e)=>{
    // insightItems.pop();
    if(url==="/personal-savings/"){
        setFeefoBadge(true)
      }
    }
    useEffect(() => {
      updatePosts()
    }, []);

  return (
    <StaticQuery
      query={FOOTER_MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
<>
                                    <nav>
                                    <div className="menu-footer-menu-container">
                                    <ul id="menu-footer-menu" className="menu">


                                          {data.wpgraphql.menuItems.nodes.map(menuItem => {
                                              return RenderMenuItem(menuItem)
                                          })}

        </ul>
        </div>
        </nav>
        {showFeefoBadge ?
          <>
        <div id="note-service-review-floating-widgetId"></div>
        </>
        :null}
        </>

          )
        }
        return null
      }}
    />
  )
}

export default FooterMenu
