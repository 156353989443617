import React, {useRef, useState,useEffect } from "react"
import {Link, StaticQuery, graphql } from "gatsby"
import Cookies from 'universal-cookie';
import MenuItem from "./MenuItem"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const SITEMAP_MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETSITEMAPMENU {
        wpgraphql {
            menuItems(where: {location: SITEMAP}, first: 300) {
                nodes {
                    ...MenuItem
                    childItems {
                      nodes {
                        ...MenuItem
                        childItems {
                          nodes {
                            ...MenuItem
                            childItems {
                              nodes {
                                ...MenuItem
                                childItems {
                                  nodes {
                                    ...MenuItem
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                }
            }
            generalSettings {
                url
            }
        }
    }
`


const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(process.env.WORDPRESS_URL, ``)
}

const RenderMenuItem = menuItem => {
  const link = createLocalLink(menuItem.url)

  //{console.log('THE LINK '+process.env.WORDPRESS_URL)}
  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    //number of children under personal savings etc
    return RenderSubMenu(menuItem)
  } else {

    return (
      <li
        key={menuItem.id}
        className="tier-2"
      >
        <Link to={link}><span>{menuItem.label}</span></Link>
      </li>
    )

  }
}

const cookies = new Cookies();

function mainSelected(classLabel){
  cookies.set('page', classLabel, { path: '/' });
}


const RenderSubMenu = menuItem => {
  const ref = useRef(null);
  const [hoverRef, isHovered] = useHover();
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const link = createLocalLink(menuItem.url)
  const classLabel=menuItem.id
  const classId=""

  function useHover() {
    const [value, setValue] = useState(false);

    const ref = useRef(null);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(
      () => {
        const node = ref.current;
        if (node) {
          node.addEventListener('mouseover', handleMouseOver);
          node.addEventListener('mouseout', handleMouseOut);

          return () => {
            node.removeEventListener('mouseover', handleMouseOver);
            node.removeEventListener('mouseout', handleMouseOut);
          };
        }
      },
      [ref.current] // Recall only if ref changes
    );

    return [ref, value];
  }
  if (classLabel==="bmF2X21lbnVfaXRlbTozODYz"  ||  classLabel==="bmF2X21lbnVfaXRlbTozMjk1"  ||  classLabel==="bmF2X21lbnVfaXRlbTozODY3") {

    const classId="main"
    return (
      <li
        key={menuItem.label}
        className={`main ${hovered ? "dHover" : ""} ${classLabel==cookies.get('page') ? "is-current" : ""}`}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onClick={() => mainSelected(classLabel)}
      >
        <Link to={link}>{menuItem.label}</Link>
        <div className="menu-secondary-menu-container">
        <ul id="menu-secondary-menu" className="menu">
          {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
          </ul>
          </div>
      </li>
    )
  }
  else if(classLabel==="bmF2X21lbnVfaXRlbTozNzgw"  ||  classLabel==="bmF2X21lbnVfaXRlbTozNzgx"  ||  classLabel==="bmF2X21lbnVfaXRlbTozMjc0" ||  classLabel==="bmF2X21lbnVfaXRlbTozMjYw" ||  classLabel==="bmF2X21lbnVfaXRlbTozODc2" ||  classLabel==="bmF2X21lbnVfaXRlbTozODcw" ||  classLabel==="bmF2X21lbnVfaXRlbTozODg0" || classLabel==="bmF2X21lbnVfaXRlbTozMjcz"  || classLabel==="bmF2X21lbnVfaXRlbTozMjg0"){
    return (
      <li
        key={menuItem.label}
        className={isHovered ? 'desk-tier-0 dHover' : 'desk-tier-0'}
        ref={hoverRef}
      >
      {link
        ? <Link to={link} activeClassName="active parent-active"><span>{menuItem.label}</span></Link>
        : <span className="menu-title-tier-1">{menuItem.label}</span>
      }
        <div className="sub-menu-wrap">
        <ul className="sub-menu">
          {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
        </ul>
        </div>
      </li>
    )
  }
else{
  return (
    <li
      key={menuItem.label}
      className="sitemap-toplevel"
    >
    {link
      ? <Link to={link} activeClassName="active"><span>{menuItem.label}</span></Link>
      : <span className="menu-title-tier-1">{menuItem.label}</span>
    }
      <ul className="sub-menu">
        {menuItem.childItems.nodes.map(item => RenderMenuItem(item))}
      </ul>
    </li>
  )
}

}

const SitemapMenu = () => {
  const [clicked, setClicked] = useState(false);
  const [mobClicked, setMobClicked] = useState(false);
  const [mobAboutClicked, setAboutMobClicked] = useState(false);
  const [mobCareersClicked, setCareersMobClicked] = useState(false);
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [popScroll, setpopScroll] = useState(true)
  const [removeTemp, setRemoveTemp] = useState(false)
  const [searchShow, setSearchShow] = useState(false)
  const toggleSearch = () => setSearchShow(!searchShow);

  const about="about"
  const careers="careers"
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const savingsBanner=""


  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y
    //if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    if(currPos.y>-1) {
      setHideOnScroll(true)
      setpopScroll(true)
      setRemoveTemp(false)
      //console.log("home"+currPos.y)
    }
    else if(currPos.y<-800) {
      setpopScroll(false)
      if(url==="/personal-savings/"){
        setRemoveTemp(true)
        //console.log("HELLO"+removeTemp)
      }
      //console.log("after"+currPos.y)
    }
    else{
      setHideOnScroll(false)
      setpopScroll(true)

    }

  }, [hideOnScroll])

  function openSearch(index) {
    setSearchShow(true);

  }
  function openDetails(index) {
    setClicked(clicked === index ? -1 : index);
  }
  function closeDetails(index) {
    setClicked(clicked === "");
  }
  function openMobile(index) {
    setMobClicked(!mobClicked);
  }
  function openAboutMobileSub() {
    setAboutMobClicked(!mobAboutClicked);
  }
  function openCareersMobileSub() {
    setCareersMobClicked(!mobCareersClicked);
  }


  return (
    <StaticQuery
      query={SITEMAP_MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
            <>



            <section class="content very-light-blue-background">
<div class="eighty-spacer"></div>
<div class="content-block clearfix">
<div class="sitemap-container">
<div class="menu-sitemap-container">
                                    <ul id="menu-sitemap" class="menu">


                                          {data.wpgraphql.menuItems.nodes.map(menuItem => {
                                            if (menuItem.childItems.nodes.length) {
                                              return RenderSubMenu(menuItem)
                                            } else {
                                              console.log(menuItem)
                                              return RenderMenuItem(menuItem)
                                            }
                                          })}

        </ul>

        </div> </div>
        </div>

        <div class="one-hundred-spacer"></div>
        </section>






        </>
          )
        }
        return null
      }}
    />
  )
}

export default SitemapMenu
