import React from "react"
import {Link, StaticQuery, graphql } from "gatsby"

import MenuItem from "./MenuItem"

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const FOOTER_QUICK_MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETFOOTERQUICKMENU {
        wpgraphql {
            menuItems(where: {location: FOOTER_QUICK_LINKS_MENU}) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(process.env.WORDPRESS_URL, ``)
}

const RenderMenuItem = menuItem => {
  const link = createLocalLink(menuItem.url)


    return (
      <li
        key={menuItem.id}
        className=""
      >
        <Link to={link}>{menuItem.label}</Link>
      </li>
    )

  }


const FooterQuickMenu = () => {
  return (
    <StaticQuery
      query={FOOTER_QUICK_MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (

                                    <nav>
                                    <div className="menu-footer-quick-links-menu-container">
                                    <ul id="menu-footer-quick-links-menu" className="menu">


                                          {data.wpgraphql.menuItems.nodes.map(menuItem => {
                                              return RenderMenuItem(menuItem)
                                          })}

        </ul>
        </div>
        </nav>

          )
        }
        return null
      }}
    />
  )
}

export default FooterQuickMenu
