/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState,useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SearchProvider from "./Search"

//import "./layout.css"
import "../assets/css/style.scss"
import Footer from "./Footer"
import SitemapMenu from "./Sitemap"
import CategoriesWidget from "./categoriesWidget"
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children}) => {

  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [showFilter,setShowFilter] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const updatePosts=(e)=>{

  if (url.indexOf("/sitemap") > -1) {
      setShowFilter(true)
    }

  }
  useEffect(() => {
    updatePosts()
  }, []);

  return (
    <>
        {children}
        {showFilter ?
          <>
          <SitemapMenu />
          <CategoriesWidget />
          </>
          :null}

          <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
