import React from "react";
import SiteSearchAPIConnector from "@elastic/search-ui-site-search-connector";
import { SearchProvider, Results, SearchBox } from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";

import "@elastic/react-search-ui-views/lib/styles/styles.css";

const connector = new SiteSearchAPIConnector({
  documentType: "htb-website",
  engineKey: "fUPAjtuuQJ5srrxZsVDf"
});

export default function App() {
  return (
    <SearchProvider
      config={{
        apiConnector: connector
      }}
    >
      <h2>Search heading</h2>
      <div className="App">
        <Layout
          header={<SearchBox />}
          bodyContent={<Results />}
        />
      </div>
    </SearchProvider>
  );
}
