import React  from 'react'

class Twitcker extends React.Component {
  constructor(props) {
    super(props);

    if (typeof document !== `undefined`) {
      this.ticker = document.createElement("script");
      this.ticker.async = true;
      this.ticker.type = "text/javascript"
      this.ticker.src = "https://api.feefo.com/api/javascript/hampshire-trust-bank";
    }
  }

  componentDidMount() {
    const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
    if(pageUrl === 
      "/personal-savings/" || 
        pageUrl.indexOf("/variable-rate-personal-savings-account/") > -1 || 
        pageUrl.indexOf("/business/savings") > -1 ||
        pageUrl.indexOf("/fixed-rate-sme-savings-accounts") > -1 || 
        pageUrl.indexOf("/fixed-rate-charity-savings-accounts") > -1 || 
        pageUrl.indexOf("/variable-rate-charity-savings-accounts") > -1 ||
        pageUrl.indexOf("/sme-savings-accounts") > -1 || 
        pageUrl.indexOf("/charity-savings") > -1 || 
        pageUrl.indexOf("/fixed-rate-accounts/") > -1 ||
        pageUrl.indexOf("/cash-isa-accounts/") > -1 ||
        pageUrl.indexOf("/variable-rate-accounts/") > -1
      ) {
      document.head.appendChild(this.ticker);
    }
  }
  render() {
    return(
      <div id="feefo-service-review-floating-widgetId"></div>
    )
  }
}

export default Twitcker
