import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Slider from "react-slick";

import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

const ALL_CATEGORIES_QUERY = graphql`
  query GetCategories {
    wpgraphql {
      category(id: "Y2F0ZWdvcnk6OQ==") {
        posts( first: 2 ) {
          nodes {
            title
            slug
            excerpt
          }
        }
      }
    }
  }
`


const CategoriesWidget = () => {

const breakpoints = useBreakpoint();

return (
  <StaticQuery
    query={ALL_CATEGORIES_QUERY}
    render={data => {
      const { category } = data.wpgraphql

      const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        arrows: false,
        // customPaging: function(i) {
        //   return (
        //     <span></span>
        //   );
        // },
        responsive: [
          {
            breakpoint: 980,
            settings: {
              variableWidth: false,
              centerMode: false
            }
          }
        ]
      };




      return (
        <section className="content very-light-blue-background">
        <div className="eighty-spacer"></div>
        <div className="content-block">
           <div className="centered-content-container">
                <div className="centered-title-holder">
                   <span>
                      Latest news
                   </span>
                </div>
           </div>
         <div className="eighty-spacer"></div>
          {breakpoints.sm ?
         <Slider className="one-flex-container news-insight news-insight-mob-slider" {...slickSettings}>
         {category.posts.nodes.length
           ? category.posts.nodes.map((cat,index) => (
             <div className="half-flex-container" key={index}>
                 <a href={`/blog/${cat.slug}`}>
                   <div className="full-width-flex-container slick-flex-mob">
                     <div className="full-width-news-content">
                     <h6>{cat.title}</h6>
                     <div className="full-width-news-content-container">
<span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: cat.excerpt }}/>
                     <div className="news-full-link">
                           Find out more
                       </div>

                 </div>

               </div>
               </div>
                             </a>
                        </div>
             ))
           : null}
           </Slider>
            :
         <div className="one-flex-container news-insight news-insight-mob-slider">
            {category.posts.nodes.length
              ? category.posts.nodes.map((cat,index) => (
                <div className="half-flex-container" key={index}>
                    <a href={`/blog/${cat.slug}`}>
                      <div className="full-width-flex-container slick-flex-mob">
                        <div className="full-width-news-content">
                        <h6>{cat.title}</h6>
                        <div className="full-width-news-content-container">
<span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: cat.excerpt }}/>
                        <div className="news-full-link">
                              Find out more
                          </div>

                    </div>

                  </div>
                  </div>
                                </a>
                           </div>
                ))
              : null}
        </div>
      }

        </div>
        <div className="eighty-spacer"></div>
        </section>
      )
    }}
  />
)
}

export default CategoriesWidget
